$(function() {
    
    function shouldShowTopBanner() {
      if (Cookies.get('pach_topbanner')==1) {
        return false;
      } 
      else {
        return true;
      }
    }
    
    if (shouldShowTopBanner()) {
      $("#topbanner").show();
      $("#topbanner_close").click(function(){
        $("#topbanner").hide();
        Cookies.set('pach_topbanner', 1, { expires: 365 });
      });
    } 
    
    $("#topbanner_close").click(function(){
      $("#topbanner").hide();
    });
    
});

//var enableTracking = (location.hostname=="www.pachyderm.com");
var enableTracking = true;

function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

function isInView(elem) {
    var documentViewTop = $(window).scrollTop();
    var documentViewBottom = documentViewTop + $(window).height();

    var elementTop = $(elem).offset().top;
    var elementBottom = elementTop + $(elem).height();

    return ((elementBottom <= documentViewBottom) && (elementTop >= documentViewTop));
}

// Tidy and comment if the below works well #todoMP
if (enableTracking) {

    // Rudderstack works best if the library follows the code that uses it
    console.log('rudderanalytics ', window.rudderanalytics ? 'Unexpected' : 'OK');

    // rudderanalytics=window.rudderanalytics=[];for(var methods=["load","page","track","identify","alias","group","ready","reset","getAnonymousId","setAnonymousId"],i=0;i<methods.length;i++){var method=methods[i];rudderanalytics[method]=function(a){return function(){rudderanalytics.push([a].concat(Array.prototype.slice.call(arguments)))}}(method)}
    // rudderanalytics.load("1iJt5EmS8gjqV8M1ZAWKy5lj6Xf","https://pachyderm-dataplane.rudderstack.com"),
    // rudderanalytics.page();

    // The code below is copied from Rudderstack and sets up a temporary object to catch events while 
    // the library is not loaded. We don't need the minified version as our optimiser will do it
    // START
    rudderanalytics = window.rudderanalytics = [];
    var methods = [
        "load",
        "page",
        "track",
        "identify",
        "alias",
        "group",
        "ready",
        "reset",
        "getAnonymousId",
        "setAnonymousId",
    ];
    for (var i = 0; i < methods.length; i++) {
        var method = methods[i];
        rudderanalytics[method] = (function (methodName) {
        return function () {
            rudderanalytics.push(
            [methodName].concat(Array.prototype.slice.call(arguments))
            );
        };
        })(method);
    }
    rudderanalytics.load('1iJt5EmS8gjqV8M1ZAWKy5lj6Xf', "https://pachyderm-dataplane.rudderstack.com");
    rudderanalytics.page();
    // END


    // Track utm if present, as with page() do this straight away via the proxy object
    if (getUrlParameter('utm_source')) {
        Cookies.set('utm_source', getUrlParameter('utm_source'), { expires: 7 });
    }
    if (getUrlParameter('utm_campaign')) {
        Cookies.set('utm_campaign', getUrlParameter('utm_campaign'), { expires: 7 });
    }
    if (getUrlParameter('utm_medium')) {
        Cookies.set('utm_medium', getUrlParameter('utm_medium'), { expires: 7 });  
    }
    if (getUrlParameter('utm_content')) {
        Cookies.set('utm_content', getUrlParameter('utm_content'), { expires: 7 });
    }
    if (getUrlParameter('utm_term')) {
        Cookies.set('utm_term', getUrlParameter('utm_term'), { expires: 7 });
    }
    var latest_utm_source   = Cookies.get('utm_source');
    var latest_utm_campaign = Cookies.get('utm_campaign');
    var latest_utm_medium   = Cookies.get('utm_medium');
    var latest_utm_content  = Cookies.get('utm_content');
    var latest_utm_term     = Cookies.get('utm_term');            
    
    if ((getUrlParameter('utm_source') || getUrlParameter('utm_campaign') || getUrlParameter('utm_medium') || getUrlParameter('utm_content') || getUrlParameter('utm_term'))) {
        rudderanalytics.track(
            "UTM",
            {
                context: {
                    traits: {
                    latest_utm_source,
                    latest_utm_campaign,
                    latest_utm_medium,
                    latest_utm_content,
                    latest_utm_term
                    }
                }
            }
        );
    }    

    // These handlers must be set up on ready  
    $(function() {

        // Handler for tracking a click event
        $("[data-clickid]").on("click",function () {
 
            function extendClickId(clickId) {
                var pathAsId = document.location.pathname.replace(/\//g,'-');
                pathAsId = pathAsId.replace(/^[-]+|[-]+$/g, '') || 'home';
                console.log(clickId + '-url-' + pathAsId );
                return clickId + '-url-' + pathAsId ;
            }

            rudderanalytics.track(
                "Click",
                {
                    context: {
                        traits: {
                            clickid: extendClickId($(this).attr("data-clickid")),
                            click_url: $(this).attr("data-url")
                        }
                    }
                }
            );
        });

        // Handler for tracking scroll complete event
        var fired = false;
        $(window).on( "scroll", function(){
            if (fired != true && $('#scroll-complete').length && isInView($('#scroll-complete'))) {
                console.log('rudderanalytics scroll >', rudderanalytics, '.');

                rudderanalytics.track("Scroll");
                fired = true;
            }
        });

    });
}
